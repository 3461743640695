
import '../App.css';
import Nav from "../components/Nav"
import { Link } from 'react-router-dom'
import { useEffect } from "react";

function Home() {
  const currentTab = "home";
  return (
    <>
      <div className='home'>
        <Nav Tab={currentTab}></Nav>
        <div className="desk-image-container">
          <img src="logorepr.png" className='desk-image' alt="" />
          <a className='check-allow' href="https://representatives.guildalpha.xyz/" target="_blank" rel='noreferrer'>Apply Now</a>
        </div>
      </div>
    </>
  );
}

export default Home;
