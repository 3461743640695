
import '../App.css';
import Nav from '../components/Nav';

function Representatives() {
    const currentTab = "discover";

    return (
        <>
            <Nav Tab={currentTab}></Nav>
            <div className="big-card">
                <div className="square1"></div>
                <div className="square2"></div>
                <div className="square-circle1"></div>
                <div className="line-top"></div>
                <div className="line-mid"></div>
                <div className="flex-inner">
                    <div className="inner-big-card">
                        <h1>Representatives</h1>
                        <div className="grid-info">
                            <div className="mini-info">
                                <h2>What is this?</h2>
                                <p>A program to support other NFT projects by buying their NFTs and airdropping them for free to active Guild holders.</p>
                            </div>
                            <div className="mini-info">
                                <h2>Why?</h2>
                                <p>We belive in growing together. The whole space is about connecting with different people and cultures. And it's always a  win-win case when an NFT project supports another NFT project.</p>
                            </div>
                            <div className="mini-info">
                                <h2>How?</h2>
                                <p>Guild Representative Programme supports other projects not only by buying their NFTs from the floor, but also by choosing the best and most active individuals at Guild community to receive those sweeped NFTs, so the supported NFT projects gets active community memebrs that help them in moving forward.
                                    <br /> After all, the NFTs wolrd is all about the community.</p>
                            </div>
                            <div className="mini-info">
                                <h2>Which?</h2>
                                <p>Any project that holders Guild Alpha Pass NFT in their wallet is eligible. Terms and conditions apply.</p>
                            </div>
                            <div className="mini-info">
                                <h2>Who?</h2>
                                <p>Any Guild holder that applies for a representative post will have a chance to receive a Free NFT from the project chosen during his/her application.</p>
                            </div>
                            <div className="mini-info">
                                <h2>Next?</h2>
                                <p>If a Guild representative is doing his/her job successfully, by engaging with the community of the supported NFT project;   He/She will have more chances of receiving another Free NFT from other project they apply for.</p>
                            </div>
                        </div>
                        <a href="https://representatives.guildalpha.xyz/" target="_blank" rel='noreferrer' className="btn">Apply</a>
                    </div>
                    <div className="inner-second">
                        <div className="inner-part">
                            <h1>Nutshell</h1>
                            <h2>1 - What's in for the Supported NFT project?</h2>
                            <p>
                                i) Sweep <br />
                                ii) New community members
                            </p>
                            <h2>2 - What's in it for Guild holders?</h2>
                            <p>
                                i) Free NFT from a project they choose. Terms & conditions apply. <br />
                                ii) Special VIP chat and extra WL spots on Guild discord.
                            </p>
                        </div>
                        <div className="inner-part">
                            <h1>T&C: Guild Representative</h1>
                            <br />
                            <p>
                                - Upon acceptance, your display picture must be changed on all social media platforms to a new picture to be agreed upon on the time of application.
                                <br /><br />
                                - You must actively participate as much as possible in community that you choose to represent Guild at. This includes both Discord and Twitter.
                                <br /><br />
                                - Selling the NFT or leaving the community will result in not accepting your applications for any future  opportunities.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="right-label">
                    <h2>GUILD REPRESENTATIVES</h2>
                </div>

            </div>
            <div className="partners">
                <h1>Partners</h1>
                <div className="partners-grid">
                    <div className="partner">
                        <img src="/assets/thehumanoids.jpg" alt="partner" />
                    </div>
                    <div className="partner">
                        <img src="/assets/crookz.jpg" alt="partner" />
                    </div>
                    <div className="partner">
                        <img src="/assets/celestialstudios.svg" alt="partner" />
                    </div>
                    <div className="partner">
                        <img src="/assets/grayboys.png" alt="partner" />
                    </div>
                </div>
            </div>

            <div className="apply" id="apply">
                <a href="https://representatives.guildalpha.xyz/" target="_blank" rel='noreferrer'>Apply <i className='arrow right'></i></a>
            </div>
        </>
    );
}

export default Representatives;
