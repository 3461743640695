
import '../App.css';
import { Link } from 'react-router-dom'

function Nav({ Tab }) {
    function toggleMenu() {
        const menu = document.getElementById("mobileNavContainer");
        const menuicon = document.getElementById("menuIcon");
        menuicon.classList.toggle("close-button");
        menu.classList.toggle("open-menu");
    }
    function toggleBuy() {
        const buycont = document.getElementById("buyContainer");
        buycont.classList.toggle("open-buy");
    }
    function toggleBuyDesk() {
        const buycont = document.getElementById("buyContainerDesk");
        buycont.classList.toggle("open-buy-desk");
    }
    return (
        <>
            <div className='mobile-nav' id="mobileNavContainer">
                <div className="mobile-nav-inner">
                    <Link className={Tab == "home" ? "link linkactive" : "link"} to="/" >HOME</Link>
                    <div className="divider"></div>
                    <Link className={Tab == "discover" ? "link linkactive" : "link"} to="/guild-representatives" >REPRESENTATIVES</Link>
            

                    <div className="divider"></div>
                    <a onClick={() => toggleBuy()}>BUY</a>
                    <div className="buy-container" id="buyContainer">
                        <a className='buy-link' href="https://opensea.io/collection/guild-alpha-pass" target="_blank">GUILD (OS) <img src="/assets/arrow2.svg" alt="" /></a>
                        <a className='buy-link' href="https://looksrare.org/collections/0x7EEEF89946Ee424dB24B0Add1F2d90a8745dDe3d?queryID=d4a028ea0796fc250648b926b04932bb" target="_blank">GUILD (LR) <img src="/assets/arrow2.svg" alt="" /></a>
                        <a className='buy-link' href="https://www.magiceden.io/collections/eth/0x7EEEF89946Ee424dB24B0Add1F2d90a8745dDe3d" target="_blank">GUILD (ME) <img src="/assets/arrow2.svg" alt="" /></a>
                    </div>
                    <div className="divider"></div>
                    <a href="https://guild-1.gitbook.io/guild/" target="_blank">DOCUMENTATION</a>
                    <div className="divider"></div>
                    <a className='social-mobile' href="https://twitter.com/Guildcall" target="_blank">TWITTER <img src="assets/twitter.svg"></img></a>
                    <div className="divider"></div>
                    <a className='social-mobile' href="https://discord.com/invite/u5tsUtsRqw" target="_blank">DISCORD <img src="assets/discord.svg"></img></a>
                    <div className="divider"></div>
                </div>


            </div>
            <div className="menu-button" id="menuIcon" onClick={() => toggleMenu()}>
                <div className="line-top"></div>
                <div className="line-bottom"></div>
            </div>
            <nav>
                <Link className='mainlogowrap' to="/"><img src="whitelogo.png" className='mainlogo' alt="" /></Link>
                <div className='hide-800'>
                    <Link className={Tab == "home" ? "link hide-800 linkactive" : "link hide-800"} to="/" >HOME</Link>
                    {/*<Link className={Tab == "discover" ? "link hide-800 linkactive" : "link hide-800"} to="/discover" >DISCOVER</Link>*/}
     
                    <Link className={Tab == "representatives" ? "link hide-800 linkactive" : "link hide-800"} to="/guild-representatives" >REPRESENTATIVES</Link>

                    <a onClick={() => toggleBuyDesk()}>BUY</a>
                    <div className="buy-container" id="buyContainerDesk">
                        <a className='buy-link' href="https://opensea.io/collection/guild-alpha-pass" target="_blank">GUILD (OS) <img src="/assets/arrow2.svg" alt="" /></a>
                        <a className='buy-link' href="https://looksrare.org/collections/0x7EEEF89946Ee424dB24B0Add1F2d90a8745dDe3d?queryID=d4a028ea0796fc250648b926b04932bb" target="_blank">GUILD (LR) <img src="/assets/arrow2.svg" alt="" /></a>
                        <a className='buy-link' href="https://www.magiceden.io/collections/eth/0x7EEEF89946Ee424dB24B0Add1F2d90a8745dDe3d" target="_blank">GUILD (ME) <img src="/assets/arrow2.svg" alt="" /></a>
                    </div>
                    <a href="https://guild-1.gitbook.io/guild/" className='hide-800' target="_blank">DOCUMENTATION</a>
                    <a className='social-mobile desk hide-800' href="https://twitter.com/Guildcall" target="_blank">TWITTER <img src="assets/twitter.svg"></img></a>
                    <a className='social-mobile desk hide-800' href="https://discord.com/invite/u5tsUtsRqw" target="_blank">DISCORD <img src="assets/discord.svg"></img></a>

                </div>
            </nav>
        </>
    );
}

export default Nav;
